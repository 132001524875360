import styled from "styled-components";
import {
  DESKTOP_1024,
  DESKTOP_1200,
  MOBILE_460,
  TABLET_600,
  TABLET_800,
  TABLET_992,
} from "../../styles/globals/sizes";
import { COLORS } from "../../styles/globals/colors";

export const HeroContainer = styled.div`
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
  overflow: hidden;
  background: ${COLORS.white};

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
  & > img,
  & > source {
    width: 100%;
    margin-right: 0%;
    object-fit: cover;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 30% 45%/cover no-repeat`
      : `background: purple;`};
      
  @media (max-width: ${TABLET_800}px) {
    height: 400px;
    display: block;
    background: ${(props) =>
      props.bgImage && `url(${props.bgMobileImage}) 10% 45%/cover no-repeat`};
  }
  }
  @media (max-width: ${TABLET_600}px) {
    background: ${(props) =>
      props.bgImage && `url(${props.bgMobileImage}) 10% 45%/cover no-repeat`};
  }
`;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_800}px) {
    max-width: 100%;
    margin: 0;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }
`;

export const HeroTextDiv = styled.div`
  padding: 50px 30px;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: 1600px) {
    width: 90%;
  }

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    padding: 36px 21px 0px;
    box-sizing: border-box;
    justify-content: ${(props) => (props.align ? props.align : "left")};
    align-items: ${(props) => (props.align ? props.align : "left")};
  }
  @media (max-width: ${MOBILE_460}px) {
    display: flex;
    flex-direction: column;
    padding: 36px 21px 0px;
  }
`;

export const Title = styled.h1`
  width: 12em;
  margin-bottom: 10px;
  color: ${COLORS.magenta};
  text-align: left;
  font: normal 57px/60px Roboto;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 14px 0;
    font: normal 45px/54px "Roboto";
  }
  @media (max-width: ${TABLET_800}px) {
    text-align: ${(props) => (props.align ? props.align : "left")};
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 92%;
    font: normal 35px "Roboto";
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_800}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Paragraph = styled.p`
  width: ${(props) => (props.paragraphWidth ? props.paragraphWidth : "41%")};
  margin: 10px 0 20px;
  color: ${COLORS.grayText};
  font-family: "Roboto";
  font-size: ${(props) => (props.fontSize ? props.fontSize : "26px")};
  font-weight: 400;
  line-height: 36px;
  @media (max-width: ${DESKTOP_1200}px) {
    width: 71%;
    line-height: 25px;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 90%;
    font: normal 18px/28px "Roboto";
    line-height: 23px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    font: normal 20px/28px "Roboto";
    text-align: ${(props) => (props.align ? props.align : "left")};
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
    font: normal 19px "Roboto";
  }
`;
