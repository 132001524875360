import React, { useState } from "react";

import ArrowIcon from './assets/arrow.svg';
import { FaqContainer, Question, Wrapper, Response, QuestionWrapper, Arrow, ArrowOpen, ResponseContainer } from './styles';

const FaqComponent = () => {
  const [renderResponse, setRenderResponse] = useState('');
  const handleOpen = (valueToOpen: string) => {
    if(renderResponse === valueToOpen) {
        setRenderResponse('');
    } else {
        setRenderResponse(valueToOpen)
    }
  }
  const questionsAndResponses = [
    {
      key: "question_1",
      question: "What is Hospice?",
      response: ["Hospice is a unique focus of care designed to assist patients with a life expectancy that can be spoken in terms of months rather than years. Hospice offers medical, emotional, social and spiritual support centered on quality-of-life considerations."],
    },
    {
      key: "question_2",
      question: "Who pays for hospice services?",
      response: ["Payment for hospice services may come from Medicare, Medicaid, Health Maintenance Organizations (HMOs) or other private insurance plans.  If you are unsure if the hospice benefit is available through your insurance plan, speak with your insurer, a hospice representative, or your human resources representative at work.  Hospice is a managed care approach to end of life care.  All care that you get for your terminal illness must be given by or arranged by the hospice interdisciplinary team."],
    },
    {
      key: "question_3",
      question: "What does hospice service provide for my loved one?",
      response: ["The Life Hospice program is invested in providing compassionate, skilled care to patients with a life-limiting illness. Through an interdisciplinary team approach, services can include",
        "· Nursing Care",
        "· Medical Social Worker",
        "· Certified Home Health Aide Care",
        "· Symptom Management",
        "· Spiritual Counseling",
        "· Specially Trained Volunteer Support"
      ],
    },
    {
      key: "question_4",
      question: "Where is Hospice care provided?",
      response: ["Hospice may be provided in many settings including the patient’s home, skilled nursing or assisted living facilities, hospitals or other facilities.  Hospice care can be provided during short-term inpatient hospital stays if necessary to manage severe symptoms.  Respite can also be available for short-term to give caregivers a brief rest from the responsibility of caring for the patient.  Care is generally provided in the home, for some, the Home may mean an extended care facility such as a nursing home or assisted living facility."],
    },
    {
      key: "question_5",
      question: "Does Life Hospice provide a Chaplain?",
      response: ["Services are available for counseling, spiritual support, and guidance 24/7.  A chaplain or spiritual counselor can make regularly scheduled visits to provide both the patient and family spiritual support.  Your personal minister or spiritual counselor may also visit, or you may choose not to receive pastoral visits. You also can choose to have both."],
    },
    {
      key: "question_6",
      question: "What are some of the benefits of Hospice?",
      response: ["Hospice services enable a patient to be at home or in an inpatient setting surrounded by loved ones. The primary focus of Hospice is to manage symptoms and control pain, enabling the patient and family to make the most of their time together. Hospice provides comfort and compassionate care during the course of a life-limiting illness and long after the loss of a loved one.",
        "A team of interdisciplinary healthcare providers, work with a patient’s doctor to provide physical, emotional, and spiritual support – centered around the patient and family’s needs and wishes.",
      ]
    },
    {
      key: "question_7",
      question: "When is the right time to start having the conversation about Hospice for a loved one?",
      response: ["Hospice is appropriate for those with a terminal illness when a goal of treatment begins to shift from curing the illness to quality of life.  This time may come well before a physician indicates that the patient’s life expectancy is likely six months or less.  Sadly, many people (and physicians) wait until the final days of life to involve hospice.  By contacting hospice earlier, patients and families have time to better understand their options and choose the path that will have the most positive impact on their quality of life.  A patient may change his or her mind at any time and can pursue other care options for any reason with notice to the Hospice provider."],
    },
    {
      key: "question_8",
      question: "What are the different levels of care hospice provides?",
      response: ["1. Routine Home Care is provided in a residential setting, patient’s home, or long-term care facility. Care could also be provided in a group home or residential setting of a patient’s choice. Services are provided on an intermittent basis defined by need, frequency and intensity defined by the Plan of Care.",
        "2. Inpatient Care is designed for short-term, acute needs in an inpatient unit, hospital or skilled nursing facility when a patient’s symptoms cannot be managed in the residential setting with the “routine home care” described above.",
        "3. Respite Care is short-term relief for a patient’s primary caregiver by transferring the patient to a hospice-appropriate skilled facility for up to five days.",
        "4. Continuous Care is provided in a residential setting when the patient’s symptoms cannot be managed with “routine level of care”. Continuous care may be provided to maintain patient care in the home setting and prevent transfer to an inpatient facility.",
      ]  ,
    },
    {
      key: "question_9",
      question: "How often will I see my Hospice Nurse?",
      response: ["A nurse visits the home on an intermittent and emergency basis to provide ongoing assessments of the physical and emotional status of the patient and to assist in symptom management. The nurse in collaboration with the physician makes changes in medications and the plan of care as needed to meet patient’s needs. The number of visits may increase as the patient’s condition changes. Because these changes may occur at any time, an on-call nurse is available 24 hours a day 7 days a week for all emergencies, questions, problems, or concerns."],
    },
    {
      key: "question_10",
      question: "Will I have Bereavement support through Hospice?",
      response: ["The bereavement coordinator counsels families by providing an initial bereavement assessment and offering support through the grief experience after the patient dies. They maintain contact with caregivers and families for 13 months after the death of a loved one."],
    },
    {
      key: "question_11",
      question: "Will Hospice provide a volunteer?",
      response: ["Based on availability, volunteers visit on a regular basis or for one-time needs. Volunteers assist with providing companionship, limited respite and a wide variety of other tasks. Many volunteers have experienced a loved one’s death and know what you are going through."],
    },
    {
      key: "question_12",
      question: "What parts of New Jersey does Life Hospice service?",
      response: ["Currently Life Hospice provides care for patients in Morris, Essex, Bergen, Passaic, Union and Somerset Counties."],
    },
    {
      key: "question_13",
      question: "Can Life Hospice service patients while they are living in an Assisted Living facility?",
      response: ["Yes"],
    },
    {
      key: "question_14",
      question: "Can Life Hospice service patients while they are living in an Nursing facility?",
      response: ["Yes"],
    },
    {
      key: "question_15",
      question: "Does Life Hospice have someone on call 24/7?",
      response: ["Life Hospice has a nurse on call 24/7. Every call is answered by a Registered Nurse, we do not use an answering service."],
    },
  ];
  return (
    <FaqContainer>
      {questionsAndResponses.map((questionAndResponse) => 
        <Wrapper key={questionAndResponse.key}>
            <QuestionWrapper onClick={() => handleOpen(questionAndResponse.key)}>
              <Question>{questionAndResponse.question}</Question>
              {renderResponse === questionAndResponse.key ? <ArrowOpen src={ArrowIcon} />: <Arrow src={ArrowIcon} />}
            </QuestionWrapper>
            <ResponseContainer>
              {renderResponse === questionAndResponse.key && 
                  questionAndResponse.response.map((responseText) =>
                      <Response>{responseText}</Response>

                  )
              }
            </ResponseContainer>
        </Wrapper>
      )}
    </FaqContainer>
  );
};

export default FaqComponent;
