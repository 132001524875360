import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";

import AOS from "aos";
import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import seoImage from "../../static/seo/seo_image.png";
import FaqComponent from "../components/Faq";
import ContactUs from "../components/ContactUs/ContactUs";
import HeroImage from "../images/faq/hero-faq.webp";
import HeroTabletImage from "../images/faq/hero-faq-tablet.webp";
import HeroMobileImage from "../images/faq/hero-faq-mobile.webp";
import HeroBanner from "../components/HeroBanner";

const FAQ = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://hospicecare.life/" />
        </Helmet>
        <Seoinfo
          siteName={
            "End-of-Life Care: Providing Comfort and Dignity  | Life Hospice"
          }
          titlePage={
            "End-of-Life Care: Providing Comfort and Dignity  | Life Hospice"
          }
          description={
            "Our mission: Compassionate and High-Quality End-of-Life Care. Meeting Medical, Spiritual, and Emotional Needs with Care and Respect."
          }
          keywords={
            "end-of-life care, comfort and dignity, patient-centered care, compassionate care, quality care"
          }
          type={"website"}
          url={"hospice.life"}
          imageFacebook={seoImage}
        />
        <HeroBanner
          HeroImage={HeroImage}
          HeroMobileImage={HeroMobileImage}
          HeroTabletImage={HeroTabletImage}
          title="LIFE HOSPICE"
          paragraph="Providing Comfort and Clarity: Your Comprehensive Guide to Hospice Care. Find answers to your most important questions about our services, support, and the compassionate care we offer to ensure peace and dignity for you and your loved ones"
        />
        <FaqComponent />
        <ContactUs />
      </Layout>
    </HelmetProvider>
  );
};

export default FAQ;
