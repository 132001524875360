import React from "react";
import {
  HeroContainer,
  HeroPicture,
  HeroTextDiv,
  InternalWrapper,
  MobileDiv,
  Paragraph,
  PhoneAndParagraph,
  Title,
} from "./styles";

const HeroBanner = ({
  HeroImage,
  HeroTabletImage,
  HeroMobileImage,
  title,
  paragraph,
  secondaryParagraph,
  paragraphWidth,
  paragraphFontSize,
  align,
}) => {
  return (
    <HeroContainer bgImage={HeroImage} alt="Hands">
      <HeroPicture>
        {/* <source srcSet={HeroImage} type="image/avif" /> */}
        <img src={HeroImage} alt="group of people" />
      </HeroPicture>
      <InternalWrapper>
        <MobileDiv
          bgImage={HeroTabletImage}
          bgMobileImage={HeroMobileImage}
          alt="Hands"
        />
        <HeroTextDiv align={align}>
          <Title align={align}>{title}</Title>
          <PhoneAndParagraph>
            <Paragraph
              align={align}
              fontSize={paragraphFontSize}
              paragraphWidth={paragraphWidth}
            >
              {paragraph}
            </Paragraph>
            {secondaryParagraph && (
              <Paragraph
                fontSize={paragraphFontSize}
                paragraphWidth={paragraphWidth}
              >
                {secondaryParagraph}
              </Paragraph>
            )}
          </PhoneAndParagraph>
        </HeroTextDiv>
      </InternalWrapper>
    </HeroContainer>
  );
};

export default HeroBanner;
